
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* // font-family: "Source Sans Pro", sans-serif; */
  transition: all 0.4s ease-in-out;
}

@font-face {
  font-family: cookies;
  src: url(./assets/font/Cookies.ttf);
}

.sweet-loading{
  /* border: 2px solid red; */
  background-color: rgba(0,0,0,.1);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}